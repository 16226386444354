<template>
	<prac-test :test=test>
	<h1>Test</h1>
	</prac-test>
</template>
<script>
import { PracTest } from "@/components";
import katex from "katex";
export default {
  name: "boolean-algebra",
  components: {
    PracTest
  },
  beforeMount() {
    /*
      
      var scriptTag = document.createElement("script");
      scriptTag.defer = true;
      scriptTag.src = "https://cdn.jsdelivr.net/npm/katex@0.10.0/dist/katex.min.js";
      scriptTag.integrity = "sha384-K3vbOmF2BtaVai+Qk37uypf7VrgBubhQreNQe9aGsz9lB63dIFiQVlJbr92dw2Lx";
      scriptTag.setAttribute('crossorigin','anonymous')
      scriptTag.id = "katexscript";
      headTag.appendChild(scriptTag);*/
  },
  mounted() {
    if (!document.getElementById("katexscript")) {
      // otherwise already loaded
      var headTag = document.getElementsByTagName("head")[0];
      var linkTag = document.createElement("link");
      linkTag.type = "text/css";
      linkTag.rel = "stylesheet";
      linkTag.id = "katexscript";
      linkTag.href =
        "https://cdn.jsdelivr.net/npm/katex@0.10.0/dist/katex.min.css";
      linkTag.integrity =
        "sha384-9eLZqc9ds8eNjO3TmqPeYcDj8n+Qfa4nuSiGYa6DjLNcv9BtN69ZIulL9+8CqC9Y";
      linkTag.setAttribute("crossorigin", "anonymous");
      headTag.appendChild(linkTag);
    }
    window.addEventListener("load", function(event) {
      var allCells = document.getElementsByClassName("cell");
      console.log(allCells.length);
      for (var i = 0; i < allCells.length; i++) {
        var cell = allCells[i];
        if (
          cell.firstChild != null &&
          cell.firstChild.textContent.includes("<span")
        ) {
          var replacementNode = document.createElement("span");
          replacementNode.innerHTML = cell.textContent;
          //var toRender = cell.textContent;
          cell.parentNode.insertBefore(replacementNode, cell);
          cell.parentNode.removeChild(cell);
          replacementNode.querySelectorAll("span").forEach(x => {
            katex.render(x.textContent, x);
          });
          //katex.render(toRender, replacementNode);//= function(){renderMathInElement(document.body);}
        }
      }
    });

    katex.render(
      "f(a,b,c) = (a^2+b^2+c^2)^3",
      document.getElementById("mykatex2")
    );
  },
  data() {
    return {
      test: {
        date: "January 2019",
        title: "Boolean Algebra",
        subtitle: "Strategies for Building Successful Teams and Organizations",
        description:
          "Online flashcards based on the book Scaling Teams by David Loftesness and Alexander Grosse",
        author: "David Loftesness and Alexander Grosse",
        references:
          "Scaling Teams by David Loftesness and Alexander Grosse (2017, O'REILLY)",
        items: [
          {
            front:
              "By what are the outputs of Combinational Logic Circuits determined?",
            back:
              "In a combinatorial system the outputs depend only on the combination of\ninput variables (at a given instant in time)."
          },
          {
            front:
              "By what are the outputs of Combinational Logic Circuits determined?",
            back:
              "The output at time t is a function of the input at time t , the output at time t-1 and the internal state."
          },
          {
            front: "What is boolean algebra?",
            back:
              "Boolean algebra is used to reduce and simplify a complex Boolean expression in order to reduce the number of logic gates required."
          },
          {
            front: "aaa",
            back:
              '<p class="latex">overline{(A land B)} leftrightarrow (overline{A})lor (overline{B}) iff \bar{AB} = \bar{A} + \bar{B}</p>'
          },
          {
            front: "What are the four logical operations?",
            back:
              "Not (¬, Negation), AND (Conjunction), OR (Disjunction), XOR (Exclusive OR)"
          },
          {
            front: "Into what form can any boolean function be put into?",
            back: "Canonical Normal Form"
          },
          {
            front: "What functions are in canonical form?",
            back:
              "Functions that are expressed as a sum of minterms (=Sum of products) or functions expressed as Product of Maxterm (=Product of sum)"
          },
          {
            front:
              "In Minterm we look for the functions that result in which output?",
            back: "The functions which output results in '1'."
          },
          {
            front: "In Maxterm we look for which functions?",
            back: "For the functions which output results in '0'."
          },
          {
            front:
              "What is the difference between the Canonical Disjunctive Normal Form and the Canonical Conjunctive Normal Form?",
            back:
              "Canonical normal form (=minterm) and Canonical Conjunctive Normal Form (=maxterm)"
          },
          {
            front: "What is one important application of boolean algebra?",
            back:
              "digital circuit design. Therefor te goal is often to minimize the number of gates."
          },
          {
            front:
              "In digital circuit design, why is a goal often to minimize the number of gates?",
            back: "Efficiency, performance, cost"
          },
          {
            front: "Why are Minterms called products?",
            back: "Because they are the logical AND of a set of variables."
          },
          {
            front: "Why are Maxterms called sums?",
            back: "Because they are the logical OR of a set of variables."
          },
          {
            front: "What are karnaugh maps?",
            back: "A method to simplify boolean algebra expression."
          },
          {
            front:
              "Can logic combination function be represented as a polynom?",
            back: "Yes"
          },
          {
            front: "What are the 4 basic boolean operations?",
            back: "AND (A.B), OR (A+B), XOR (⊕),"
          },
          {
            front: "What does A' mean? And A''?",
            back: "A' = Not, A'' = A (Involution=double negation)"
          },
          {
            front: 'What does "A + B" represent?',
            back: "A or B"
          },
          {
            front: "Give an example of double negation.",
            back: "(x')' = x"
          },
          {
            front: "What is x(x + y)",
            back: "Absorption = x + xy = x"
          },
          {
            front: "A + 1 = ?",
            back: "1 (Identity)"
          },
          {
            front: "A + A = ?",
            back: "A"
          },
          {
            front: "A. A = ?",
            back: "A ∧ A = A"
          },
          {
            front: "A. 1 = ?",
            back: 0
          },
          {
            front: "A. 0 = ?",
            back: "A"
          },
          {
            front: "A + NOT A = ?",
            back: 1
          },
          {
            front: "A. NOT A = ?",
            back: 0
          },
          {
            front: "NOT (A + B) = ?",
            back: "NOT A. NOT B"
          },
          {
            front: "NOT (A. B) = ?",
            back: "NOT A + NOT B"
          },
          {
            front: "XY' + XY = ?",
            back: "X"
          },
          {
            front: "XY + X = ?",
            back: "= X(X + Y) = X  (Absorption)"
          },
          {
            front: "(A +B')B = ?",
            back: "AB"
          },
          {
            front: "Simplify   Y = A ∧ B ∨ B ∧ C ∧ (B ∨ C)",
            back: "Y = (A ∧ B) ∨ (B ∧ C)"
          },
          {
            front: "(X + Y)(X + Z) = ?",
            back: "X + YZ"
          },
          {
            front: "De Morgan's law: (XY...)' = ?",
            back: "X' + Y' + ...."
          },
          {
            front:
              'De Morgan&lsquo;s (Sums): (X + Y + Z + ...)<span class="latex">x^2</span> = ?',
            back: "X'Y'Z'..."
          }
        ]
      }
    };
  }
};
</script>
